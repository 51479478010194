<template>
  <div class="wrapper" :style="{ 'background-color': backgroundColor }">
    <div class="dom1">
      <span class="icon1 iconfont icon-huanyingye-xiaoxi"></span>
      <span class="icon2 iconfont icon-huanyingye-wenjian"></span>
      <span class="icon3 iconfont icon-huanyingye-zhuzhuangtu"></span>
      <span class="icon4 iconfont icon-huanyingye-xiaolian"></span>
      <span class="icon5 iconfont icon-huanyingye-quxiantu"></span>
      <span class="icon6 iconfont icon-huanyingye-wenjianjia"></span>
      <div class="load load1"></div>
      <div class="load load2"></div>
      <div class="load load3"></div>
      <div class="load4" :class="{ 'load4-icon': !entryHo.welcomeAvatarUrl }">
        <img :src="entryHo.welcomeAvatarUrl" v-if="entryHo.welcomeAvatarUrl" />
        <i class="iconfont icon7 icon-touxiang" v-else></i>
      </div>
    </div>
    <div class="dom2">
      <div class="tit1">“ HELLO 你好 ”</div>
      <div class="tit2">
        <pre>{{ entryHo.welcomeContent }}</pre>
      </div>
    </div>
  </div>
</template>
<script>
import untis from "../../utils";
export default {
  data() {
    return {
      backgroundColor: "#2E98FF",
      entryHo: {
        // 欢迎页
        welcomeContent: "", // 欢迎词
        welcomeAvatarUrl: "", // 头像
      },
    };
  },

  created() {
    this.getInit();
  },
  mounted() {},
  methods: {
    getInit() {
      let con = JSON.parse(untis.getSessionStorage("hello"));
      this.entryHo.welcomeContent = con.welcomeContent; // 欢迎词
      if (con.welcomeAvatarUrl) {
        this.entryHo.welcomeAvatarUrl =
          untis.getImgUrl() + con.welcomeAvatarUrl; // 头像
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  .dom1 {
    display: inline-block;
    position: relative;
    width: 160px;
    height: 160px;
    margin-top: 100px;
    .iconfont {
      position: absolute;
      font-size: 16px;
      opacity: 0.4;
      z-index: 1;
      // animation: animloader14 1s linear;
    }
  }
  .dom2 {
    color: #fff;
    padding: 50px 40px 60px 40px;
    box-sizing: border-box;
  }

  .icon1 {
    left: 36px;
    top: -6px;
    animation: icon1 1s linear;
  }
  .icon2 {
    left: 150px;
    top: 20px;
    animation: icon2 1s linear;
  }
  .icon3 {
    left: 160px;
    top: 90px;
    animation: icon3 1s linear;
  }
  .icon4 {
    right: 24px;
    bottom: -17px;
    animation: icon4 1s linear;
  }
  .icon5 {
    left: 4px;
    bottom: -5px;
    animation: icon5 1s linear;
  }
  .icon6 {
    left: -24px;
    top: 68px;
    animation: icon6 1s linear;
  }
  .icon7 {
    font-size: 48px !important;
    opacity: 1 !important;
    color: #bfbfbf;
    right: 0;
    left: 0;
    top: 15%;
  }
  .load4-icon {
    background-color: #f5f5f5;
  }
  .load4 {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    position: absolute;
    margin: 0 auto;
    right: 0;
    bottom: 0;
    left: 0;
    top: 30%;
    display: inline-block;
    border: 3px solid #ffffff;
    animation: animloader14 1s linear;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .load {
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    position: absolute;
    margin: 0 auto;
    right: 0;
    bottom: 0;
  }
  .load1 {
    width: 140px;
    height: 140px;
    left: 0;
    top: 11%;
    animation: clippath1 3s linear infinite;
    &::before {
      content: "";
      position: absolute;
      left: 50px;
      top: 0;
      background: #fff;
      width: 3px;
      height: 3px;
      border-radius: 50%;
    }
  }
  .load2 {
    width: 110px;
    height: 110px;
    left: 0;
    top: 20%;
    animation: clippath2 5s linear 0.4s infinite;

    &::before {
      content: "";
      position: absolute;
      left: 38px;
      top: 0;
      background: #fff;
      width: 3px;
      height: 3px;
      border-radius: 50%;
    }
  }
  .load3 {
    width: 100px;
    height: 100px;
    left: 0;
    top: 23%;
    animation: clippath3 8s linear 0.6s infinite;
    &::before {
      content: "";
      position: absolute;
      left: 34px;
      top: 0;
      background: #fff;
      width: 3px;
      height: 3px;
      border-radius: 50%;
    }
  }
  .tit1 {
    font-size: 18px;
  }
  .tit2 {
    font-size: 14px;
    line-height: 18px;
    margin-top: 60px;
    text-align: left;
    pre {
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
    }
  }
}
@keyframes clippath3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes clippath2 {
  0% {
    transform: rotate(0eg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes clippath1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animloader14 {
  0% {
    transform: scale(0.2);
    // opacity: 0.4;
  }
  100% {
    transform: scale(1);
    // opacity: 1;
  }
}

@keyframes icon1 {
  0% {
    opacity: 0;
    left: 50%;
    top: 50%;
  }
  100% {
    opacity: 1;
    left: 36px;
    top: -6px;
  }
}
@keyframes icon2 {
  0% {
    opacity: 0;
    left: 50%;
    top: 50%;
  }
  100% {
    opacity: 1;
    left: 150px;
    top: 20px;
  }
}
@keyframes icon3 {
  0% {
    opacity: 0;
    left: 50%;
    top: 50%;
  }
  100% {
    opacity: 1;
    left: 160px;
    top: 90px;
  }
}
@keyframes icon4 {
  0% {
    opacity: 0;
    left: 50%;
    bottom: 50%;
  }
  100% {
    opacity: 1;
    right: 24px;
    bottom: -17px;
  }
}
@keyframes icon5 {
  0% {
    opacity: 0;
    left: 50%;
    bottom: 50%;
  }
  100% {
    opacity: 1;
    left: 4px;
    bottom: -5px;
  }
}
@keyframes icon6 {
  0% {
    opacity: 0;
    left: 50%;
    top: 50%;
  }
  100% {
    opacity: 1;
    left: -24px;
    top: 68px;
  }
}
</style>